<script setup>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

import { useAuthStore } from '@/stores';

const schema = Yup.object().shape({
    email: Yup.string().required('email is required'),
    password: Yup.string().required('Password is required')
});

function onSubmit(values, { setErrors }) {
    const authStore = useAuthStore();
    const { email, password } = values;

    return authStore.login(email, password)
        .catch(error => setErrors({ apiError: error }));
}

function socialRedirectYandex(){
    window.location='https://api.bmlabs.ru/login/yandex'
}
function socialRedirectGoogle(){
    window.location='https://api.bmlabs.ru/login/google'
}
</script>

<template>
    <div class="login">
        <img src="/logo_new.svg" class="login__logo" width="200px" alt="BMlabs">
        <Form class="max-w-sm mx-auto" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
            <div class="mb-5">
                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail</label>
                <Field name="email" type="text"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    :class="{ 'is-invalid': errors.email }" />
                <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
            <div class="mb-5">
                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Пароль</label>
                <Field name="password" type="password"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    autocomplete="on"
                    :class="{ 'is-invalid': errors.password }" />
                <div class="invalid-feedback">{{ errors.password }}</div>
            </div>
            <div class="mb-5">
                <button
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    :disabled="isSubmitting">
                    <!-- <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1">++</span> -->
                    Авторизоваться
                </button><br>
            </div>

            <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{ errors.apiError }}</div>
        </Form>
            <div class="flex gap-2">
                <button @click="socialRedirectGoogle"><img src="/google.svg" width="20px" alt="Войти через google"></button><br>
                <button @click="socialRedirectYandex"><img src="/yandex.svg" width="20px" alt="Войти через yandex"></button><br>
            </div>

            <router-link class="mt-2.5" :to="'forgot-password'">Восстановить пароль</router-link><br>
            <router-link class="-mt-2.5" :to="'register'">Регистрация</router-link>
    </div>
</template>
<style scoped lang="scss">
.login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
}
</style>