import { createApp } from 'vue'
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Aura from '@primevue/themes/aura';
import 'primeicons/primeicons.css';
import './style.css'
import App from './App.vue'
import router from "./router/index";
import Echo from 'laravel-echo';
import Pusher from "pusher-js"
import * as Sentry from "@sentry/vue";


let accessToken = '';
if(localStorage.getItem("user") != null){
  accessToken = JSON.parse(localStorage.getItem("user"))?.access_token
}

window.echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    forceTLS: true,
    authEndpoint: "https://api.bmlabs.ru/api/broadcasting/auth",
    auth: {
        headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "application/json"
        }
    }
})

const app = createApp(App);
app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});
app.use(ToastService);
app.component('Toast', Toast);
app.use(createPinia());
app.use(router);

Sentry.init({
    app,
    dsn: "https://cdf5a31bc778d141fbd7e1d43912ef3e@o4507747759292416.ingest.de.sentry.io/4507747760930896",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api.bmlabs\.ru\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.mount('#app');
