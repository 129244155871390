<script setup>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

import { useAuthStore } from '@/stores';

const schema = Yup.object().shape({
    email: Yup.string().required('Email обязательно'),
});

import { useToast } from "primevue/usetoast";
const toast = useToast();

function onSubmit(values, { setErrors }) {
    const authStore = useAuthStore();
    const { email } = values;

    return authStore.forgotPassword(email)
        .then((resp) => toast.add({ severity: 'info', summary: '', detail: authStore.statusNotify, life: 3000 }))
        .catch(error => setErrors({ apiError: error }));
}
</script>

<template>
    <div class="login">
        <img src="/logo_new.svg" class="login__logo" width="200px" alt="BMlabs">
        <Form class="max-w-sm mx-auto" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
            <div class="mb-5">
                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail</label>
                <Field name="email" type="text"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    :class="{ 'is-invalid': errors.email }" />
                <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
            <div class="mb-5">
                <button
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    :disabled="isSubmitting">
                    Отправить на почту
                </button><br>
                <router-link :to="'login'">Авторизоваться</router-link>
            </div>

            <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{ errors.apiError }}</div>
        </Form>
    </div>
</template>
<style scoped lang="scss">
.login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
}
</style>