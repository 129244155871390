<script setup>
import { computed, onMounted  } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore} from '@/stores';

const route = useRoute(); // Получаем объект маршрута
const authStore = useAuthStore();

onMounted(() => {
  authStore.loginYandex(route.query) 
})

</script>
<template>
  Авторизуемся в yandex...
</template>